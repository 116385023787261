import {
  SET_EVENT_INFO,
} from '../types';

const INITIAL_STATE = {
  // event_id: undefined,
  // event_name: undefined,
  // start_date: undefined,
  // end_date: undefined,
};

const INITIAL_ACTION = {
  type: '',
  payload: '',
};

export default function(state = INITIAL_STATE, action = INITIAL_ACTION) {
  switch (action.type) {
    case SET_EVENT_INFO:
      return action.payload;
  }

  return state;
}
