import check from './check.api';
import survey from './survey.api';
import event from './events.api';
import auth from './auth.api';
import demo from './demo.api';

const API = (() => {
  const groups = {};

  const apiGroups = [
    check,
    survey,
    event,
    auth,
    demo,
  ];

  apiGroups.map(api => {
    const apiGroup = new api();
    const apiGroupName = apiGroup.name;
    if (!apiGroupName) {
      throw new Error('Api apiGroup missing required "name" property');
    }
    groups[apiGroupName] = apiGroup;
  });

  return groups;
})();

export default API;
