import {
  SET_DEMO_EVENTS,
  CLEAR_DEMO_EVENTS,
} from '../types';
import {
//     setAuthLoading,
//     setSeason,
  signinAuth,
} from './';

export function fetchDemoEvents() {
  return function(dispatch, API) {
    return API.demo.fetchEvents()
      .then((response) => {
        dispatch({
          type: SET_DEMO_EVENTS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function demoSignin(eventId) {
  return function(dispatch, API) {
    return API.demo.demoSignin({ eventId })
      .then((response) => {
        return dispatch(signinAuth(response.data.ffs_event_id, response.data.digital_event_code));
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function clearDemoEvents() {
  return function(dispatch) {
    dispatch({
      type: CLEAR_DEMO_EVENTS,
    });
  };
}
