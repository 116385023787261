const fieldKeys = [
  'phoneNumber',
];

const fieldKeysRequired = [
  'phoneNumber',
];

const fieldKeysMinimumLength = [
  'phoneNumber',
];

const fieldKeysRegex = [
  'phoneNumber',
];

const isPhoneNumber = new RegExp('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}');

export default {
  fieldKeys,
  defaultValues: {
    phoneNumber: '',
  },
  validate(fields) {
    const errors = {};

    fieldKeysRequired.forEach(key => {
      if (fields[key] === '' || fields[key] === null) {
        return errors[key] = '*Required';
      }
    });

    fieldKeysMinimumLength.forEach(key => {
      if (fields[key].length && fields[key].length < 7) {
        return errors[key] = '*Must be a complete phone number';
      }
    });

    fieldKeysRegex.forEach(key => {
      if (fields[key].length && !isPhoneNumber.test(fields[key])) {
        return errors[key] = '*Please enter a valid phone number';
      }
    });

    return errors;
  },

};
