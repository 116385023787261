import { combineReducers } from 'redux';
import { DESTROY_ALL } from '../types';

import loading from './loading.reducer';
import submitting from './submitting.reducer';
import form from './form.reducer';
import error from './error.reducer';
import authenticated from './auth.reducer';
import event from './event.reducer';
import check_in from './check_in.reducer';
import check_out from './check_out.reducer';
import stats from './stats.reducer';
import app from './app.reducer';
import demo from './demo.reducer';

const appReducer =  combineReducers({
  loading,
  submitting,
  form,
  error,
  authenticated,
  event,
  check_in,
  check_out,
  stats,
  app,
  demo,
});

const rootReducer = (state, action) => {
  if (action.type === DESTROY_ALL) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
