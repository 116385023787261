import {
  SET_LANGUAGE,
  UPDATE_STATUS,
  SET_SEASON,
  SET_GLOBAL_SETTINGS,
} from '../types';

const INITIAL_STATE = {
  language: 'en',
  sms_status: {
    loading: null,
    message: '',
    type: '',
  },
  season: null,
  global_settings: {
    twilio_validation: null,
  },
};
const INITIAL_ACTION = { type: '', payload: {} };

export default function(state = INITIAL_STATE, action = INITIAL_ACTION) {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };

    case UPDATE_STATUS:
      return {
        ...state,
        ...action.payload,
      };

    case SET_SEASON:
      return {
        ...state,
        season: action.payload,
      };

    case SET_GLOBAL_SETTINGS:
      return {
        ...state,
        global_settings: action.payload,
      };
  }
  return state;
}
