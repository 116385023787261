import { keyBy } from 'lodash';

import {
  SET_CHECK_IN_DATA,
  TOGGLE_CHECKED_IN,
} from '../types';

const INITIAL_STATE = {};

const INITIAL_ACTION = {
  type: '',
  payload: '',
};

export default function(state = INITIAL_STATE, action = INITIAL_ACTION) {
  switch (action.type) {
    case SET_CHECK_IN_DATA:
      return keyBy(action.payload, 'survey_id');

    case TOGGLE_CHECKED_IN:
      return {
        ...state,
        [action.payload.survey_id]: action.payload,
      };
  }

  return state;
}
