
/*
* Try to keep in the same order as
* the form so it's easy to match up
*/


const fieldKeys = [
  'event_name',
  'event_type',
];

export default {
  fieldKeys,
  defaultValues: {
    event_name: '',
    event_type: '',
  },
};
