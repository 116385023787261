
/**
 * Loading Keys
 * ------------
 */
export default {
  auth: 'auth',
  checkInData: 'checkInData',
  checkOutData: 'checkOutData',
  stats: 'stats',
};
