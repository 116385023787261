
const fieldKeys = [
  'eventId',
  'password',
];

const fieldKeysRequired = [
  'eventId',
  'password',
];

export default {
  fieldKeys,
  defaultValues: {
    // eventId: '11111',
    // password: '11111',
    eventId: '',
    password: '',
  },
  validate(fields) {
    const errors = {};

    fieldKeysRequired.forEach(key => {
      if (fields[key] === '' || fields[key] === null) {
        errors[key] = '*Required';
      }
    });

    return errors;
  },

};
