
// General Action Types
export const DESTROY_ALL = 'DESTROY_ALL';
export const SET_LOADING = 'SET_LOADING';
// export const SET_SUBMITTING = 'SET_SUBMITTING';

// Auth Action Types
export const SET_USER_AUTH = 'SET_USER_AUTH';
export const SET_USER_UNAUTH = 'SET_USER_UNAUTH';

// Form Action Types
export const INITIALIZE_FORM = 'INITIALIZE_FORM';
export const VALIDATE_EDIT_FIELD = 'VALIDATE_EDIT_FIELD';
export const SET_FIELDS = 'SET_FIELDS';
export const SET_DIRTY = 'SET_DIRTY';
export const VALIDATE_ALL = 'VALIDATE_ALL';
export const CLEAR_FIELDS = 'CLEAR_FIELDS';
export const SET_SUBMITTING = 'SET_SUBMITTING';

// Error Action Types
export const SET_ERROR = 'SET_ERROR';

// Event Action Types
export const SET_EVENT_INFO = 'SET_EVENT_INFO';
export const SET_GLOBAL_SETTINGS = 'SET_GLOBAL_SETTINGS';

// Check In Action Types
export const SET_CHECK_IN_DATA = 'SET_CHECK_IN_DATA';
export const TOGGLE_CHECKED_IN = 'TOGGLE_CHECKED_IN';


// Check Out Action Types
export const SET_CHECK_OUT_DATA = 'SET_CHECK_OUT_DATA';
export const TOGGLE_CHECKED_OUT = 'TOGGLE_CHECKED_OUT';

// Stats Action Types
export const SET_STATS_DATA = 'SET_STATS_DATA';

// App Actions
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_SEASON = 'SET_SEASON';
export const UPDATE_STATUS = 'UPDATE_STATUS';

// Demo Actions
export const SET_DEMO_EVENTS = 'SET_DEMO_EVENTS';
export const CLEAR_DEMO_EVENTS = 'CLEAR_DEMO_EVENTS';
