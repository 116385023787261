import ApiGroup from './ApiGroup';

export default class demoApi extends ApiGroup {
  constructor() {
    super({
      name: 'demo',
    });
  }

    fetchEvents = () => (
      this.get('/events')
    );

    demoSignin = (data) => (
      this.post({
        endpoint: `/event`,
        data,
      })
    );

    createEvent = (data) => (
      this.post({
        endpoint: `/create`,
        data,
      })
    );
}
