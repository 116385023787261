
export default function isConnected() {
  return (
    fetch('https://www.google.com', {
      method: 'get',
      mode: 'no-cors',
    }).then(() => {
      return Promise.resolve(true);
    })
      .catch(() => {
        return Promise.reject(false);
      })

  );
}
