import nap from 'utils/nap';

import {
  SET_STATS_DATA,
} from '../types';

import {
  setStatsLoading,
} from './';

export function getStats(event) {
  return function(dispatch, API) {
    const {
      event_id,
    } = event;

    dispatch(setStatsLoading(true));

    return nap(300, API.event.getStats({ event_id }))
      .then(stats => {
        dispatch({
          type: SET_STATS_DATA,
          payload: stats.data[0],
        });

        dispatch(setStatsLoading(false));

        return Promise.resolve(true);
      })
      .catch((error) => {
        dispatch(setStatsLoading(false));

        return Promise.reject(error);
      });
  };
}

export function clearStats() {
  return {
    type: SET_STATS_DATA,
    payload: {},
  };
}
