exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._Main-module__container___kugXM{-ms-filter:\"progid:DXImageTransform.Microsoft.Alpha(Opacity=95)\";opacity:.95;z-index:100;background-color:white;top:0;display:flex;align-items:center;color:#bf2828;flex-direction:column;position:sticky;position:-webkit-sticky}._Main-module__container___kugXM p{margin:0;padding:0}._Main-module__container___kugXM ._Main-module__warning___1wYoo{margin:0;padding:0;font-size:25px}\n", ""]);

// exports
exports.locals = {
	"container": "_Main-module__container___kugXM",
	"warning": "_Main-module__warning___1wYoo"
};