import React, { Component } from 'react';

// import DevTools from './DevTools';
import Main from './Main/Main';

class App extends Component {
  render() {
    return (
      <div className="app">

        <Main />

        {/* <DevTools /> */}

      </div>
    );
  }
}

export default App;
