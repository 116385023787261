import ApiGroup from './ApiGroup';

export default class checkAPI extends ApiGroup {
  constructor() {
    super({
      name: 'check',
    });
  }

    in_data = (data) => (
      this.post({
        endpoint: `/in/data`,
        data,
      })
    );

    ['in'] = (data) => (
      this.post({
        endpoint: `/in`,
        data,
      })
    );

    out_data = (data) => (
      this.post({
        endpoint: `/out/data`,
        data,
      })
    );

    ['out'] = (data) => (
      this.post({
        endpoint: `/out`,
        data,
      })
    );
}
