import ApiGroup from './ApiGroup';

export default class authAPI extends ApiGroup {
  constructor() {
    super({
      name: 'auth',
    });
  }

    signin = (data) => (
      this.post({
        endpoint: `/signin`,
        data,
      })
    );

    verify = () => (
      this.get(`/verify`)
    );

    setLoggedIn = () => (
      this.get(`/set_logged_in`)
    );

    autoLogin = (token) => (
      this.post({
        endpoint: `/auto_login`,
      }, {
        headers: { 'authorization': token },
      })
    );

    handleSMSLogin = (data) => (
      this.post({
        endpoint: `/sms_auto_login`,
        data,
      }, {
        headers: { 'authorization': data.token },
      })
    );


    hashToToken = (data) => (
      this.post({
        endpoint: `/hash_to_token`,
        data,
      })
    );
}
