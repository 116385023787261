import {
  INITIALIZE_FORM,
  VALIDATE_EDIT_FIELD,
  SET_FIELDS,
  SET_DIRTY,
  VALIDATE_ALL,
  CLEAR_FIELDS,
} from '../types';

import {
  defaultFormValues,
} from 'definitions/form_configs';

const SET_SUBMITTING = 'SET_SUBMITTING';

const defaultFormState = {
  submitting: false,
  dirty: false,
  initialized: false,
  fields: {},
  errors: {},
};

const INITIAL_STATE = {
  leadForm: defaultFormState,
};


const INITIAL_ACTION = { type: '', payload: {} };

export default function(state = INITIAL_STATE, action = INITIAL_ACTION) {
  let form_id; let DefaultValue;

  if (action.payload) {
    // eslint-disable-next-line prefer-destructuring
    form_id = action.payload.form_id;
    DefaultValue = defaultFormValues(form_id);
  }

  switch (action.type) {
    case INITIALIZE_FORM:
      return {
        ...state,
        [form_id]: {
          ...state[form_id],
          ...setDefaultValues(state[form_id]),
          initialized: true,
        },
      };

    case SET_FIELDS:
      return {
        ...state,
        [form_id]: {
          ...state[form_id],
          fields: setInitialValues(action.payload.data),
        },
      };

    case SET_SUBMITTING:
      return {
        ...state,
        [form_id]: {
          ...state[form_id],
          submitting: action.payload.data,
        },
      };

    case SET_DIRTY:
      return {
        ...state,
        [form_id]: {
          ...state[form_id],
          dirty: action.payload.data,
        },
      };

    case VALIDATE_EDIT_FIELD:
      return {
        ...state,
        [form_id]: {
          ...state[form_id],
          fields: updateField(state[form_id], action.payload.data),
          errors: {
            ...state[form_id].errors,
            ...validateField(action.payload.data),
          },
        },
      };


    case VALIDATE_ALL:
      return {
        ...state,
        [form_id]: {
          ...state[form_id],
          errors: validateAll(state[form_id]),
        },
      };


    case CLEAR_FIELDS:
      return {
        ...state,
        [form_id]: defaultFormState,
      };

    default:
      return state;
  }

  // Helper Functions
  // ————————————————

  function setDefaultValues(fieldState) {
    const fields = {};
    const errors = {};
    const validKeys = DefaultValue.fieldKeys.filter(o => fieldState.fields[o]);

    DefaultValue.fieldKeys.forEach(field => {
      fields[field] = (
        DefaultValue.defaultValues[field] !== undefined
          ? DefaultValue.defaultValues[field]
          : null
      );
      errors[field] = '';
    });

    validKeys.forEach(value => {
      fields[value] = fieldState.fields[value];
    });

    return {
      fields,
      errors,
    };
  }

  function setInitialValues(payload) {
    const fields = {};
    DefaultValue.fieldKeys.forEach(key => {
      fields[key] = (
        payload[key] !== null && payload[key] !== ''
          ? payload[key]
          : DefaultValue.defaultValues[key]
      );
    });

    return fields;
  }

  function updateField(state, payload) {
    return {
      ...state.fields,
      [payload.key]: (
        payload.value !== undefined
          ? payload.value
          : DefaultValue.defaultValues[payload.key] || null
      ),
    };
  }

  function validateAll(state) {
    return {
      ...state.errors,
      ...DefaultValue.validate(state.fields),
    };
  }

  function validateField(payload) {
    return DefaultValue.validate({
      [payload.key]: payload.value,
    });
  }
}
