import {
  SET_DEMO_EVENTS,
} from '../types';

const INITIAL_STATE = {
  events: [],
};

const INITIAL_ACTION = {
  type: '',
  payload: '',
};

export default function(state = INITIAL_STATE, action = INITIAL_ACTION) {
  switch (action.type) {
    case SET_DEMO_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
  }

  return state;
}
