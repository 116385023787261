import {
  SET_LOADING,
} from '../types';

import LOADING_KEY from 'definitions/keys/loading.keys';

export function setAuthLoading(isLoading) {
  return setDataLoading(LOADING_KEY.auth, isLoading);
}

export function setCheckInDataLoading(isLoading) {
  return setDataLoading(LOADING_KEY.checkInData, isLoading);
}

export function setCheckOutDataLoading(isLoading) {
  return setDataLoading(LOADING_KEY.checkOutData, isLoading);
}


export function setStatsLoading(isLoading) {
  return setDataLoading(LOADING_KEY.stats, isLoading);
}


function setDataLoading(key, isLoading) {
  return {
    type: SET_LOADING,
    payload: {
      [key]: isLoading,
    },
  };
}
