const { detect } = require('detect-browser');

/* list of browser types/keys */
// https://github.com/DamonOehlman/detect-browser/blob/master/src/index.ts

const supportedOS = [
  'iOS',
  'Android OS',
  'BlackBerry OS',
  'Windows Mobile',
  'Amazon OS',
  'Windows 7',
  'Windows 8',
  'Windows 8.1',
  'Windows 10',
  'Linux',
  'Mac OS',
  'Chrome OS',
];

const supportedBrowsers = {
  'crios': {
    minVersion: 65,
  },
  'chrome': {
    minVersion: 30,
  },
  'chromium-webview': {
    minVersion: 30,
  },
  'edge-chromium': {
    minVersion: 30,
  },
  'firefox': {
    minVersion: 22,
  },
  'opera': {
    minVersion: 56,
  },
  // 'ie': {
  //     minVersion: 9,
  // },
  'edge': {
    minVersion: 15,
  },
  'edge-ios': {
    minVersion: 44,
  },
  'safari': {
    minVersion: 9.1,
  },
  'samsung': {
    minVersion: 6,
  },
  'ios': {
    minVersion: 10,
  },
  'ios-webview': {
    minVersion: 0,
  },
  'bb10': {
    minVersion: 10,
  },
  'android': {
    minVersion: 3,
  },
  'fxios': {
    minVersion: 14,
  },
  'facebook': {
    minVersion: 0,
  },
  'instagram': {
    minVersion: 0,
  },
  'FBAV': {
    minVersion: 80,

  },
};


const BROWSER_MESSAGE = (`
This browser is not supported.
Please use a different browser, another device,
or check-in at the registration table upon arrival to the event.
`);

const VERSION_MESSAGE = (`
This browser version is not supported.
Please use a different browser, another device,
or check-in at the registration table upon arrival to the event.
`);

const OS_MESSAGE = (`
This operating system is not supported.
Please use another device or check-in at the registration table upon arrival to the event.
`);

const PRE_SURVEY_MESSAGE = (`
Warning: Your browser does not meet the requirements to complete the pre-drive survey.
Please use another device or check-in at the registration table upon arrival to the event.
`);

const POST_SURVEY_MESSAGE = (`
Warning: Your browser does not meet the requirements to complete the post-drive survey. 
Please use another device or update to the latest browser
`);

export default function handleBrowser() {
  const browser = detect();

  const canvasSupported = !!window.HTMLCanvasElement;

  if (!canvasSupported) {
    blockUnsupportedBrowser(BROWSER_MESSAGE);
    return false;
  }

  if (!browser) {
    blockUnsupportedBrowser(BROWSER_MESSAGE);
    return false;
  }

  console.log(browser.name, browser.version, browser.os);

  const testType = (
    // 'browser' ||
    // 'version' ||
    // 'os' ||
    ''
  );

  /* quick & easy tests */
  if (testType) {
    switch (testType) {
      case 'browser': testUnsupportedBrowser(browser, 'fake');
        break;
      case 'os': testUnsupportedOS(browser, 'Windows XP');
        break;
      case 'version': testUnsupportedVersion(browser, 'chrome', 49);
        break;
    }
  }

  for (const browserName in supportedBrowsers) {
    if (!supportedBrowsers.hasOwnProperty(browserName)) continue;

    const supportedBrowser = supportedBrowsers[browserName];

    if (browserName === browser.name) {
      // console.log('browser:', JSON.stringify(browser, null, 2));

      if (getBrowserVersion(browser.version) < supportedBrowser.minVersion) {
        blockUnsupportedBrowser(VERSION_MESSAGE);
        return false;
      }

      if (!supportedOS.includes(browser.os)) {
        blockUnsupportedBrowser(OS_MESSAGE);
        return false;
      }

      return true;
    }
  }

  blockUnsupportedBrowser(BROWSER_MESSAGE);
  return false;
}

function getBrowserVersion(_version) {
  let version = _version;
  if (version.indexOf('.') > -1) {
    const versionParts = version.split('.');
    version = [ versionParts[0], versionParts[1] ].join('.');
  }
  return Number(version);
}

function blockUnsupportedBrowser(_reason) {
  let reason = _reason;
  if (window.location.hash) {
    if (window.location.hash.includes('?path=')) {
      reason = POST_SURVEY_MESSAGE;
    } else {
      reason = PRE_SURVEY_MESSAGE;
    }
  }
  window.location.replace(`/browser-support?reason=${encodeURIComponent(reason)}`);
}

function testUnsupportedVersion(browser, name, version) {
  if (browser.name === name) {
    browser.version = version.toString();
  }
}

function testUnsupportedBrowser(browser, name) {
  browser.name = name;
}

function testUnsupportedOS(browser, os) {
  browser.os = os;
}

