import axios from 'axios';
import moment from 'moment';
import jstz from 'jstz';
import { API_URL } from 'utils/api_url';
import JWT from 'utils/JWT';

const httpClient = configureHttp(API_URL);

export default class ApiGroup {
  constructor({ name }) {
    this._httpClient = httpClient;
    this._baseURL = API_URL;
    this._name = name;
    this._basePath = `/${name}`;
  }

  get httpClient() {
    return this._httpClient;
  }

  get name() {
    return this._name;
  }

  get baseURL() {
    return this._baseURL;
  }

  get basePath() {
    return this._basePath;
  }

  post({ endpoint, data }, config = {}) {
    return this.httpClient({
      ...config,
      method: 'POST',
      url: `${this.basePath}${endpoint}`,
      data,
    });
  }

  get(endpoint, params = {}, config = {}) {
    return this.httpClient({
      ...config,
      method: 'GET',
      url: `${this.basePath}${endpoint}`,
      params,
    });
  }
}

function configureHttp(baseURL) {
  axios.defaults.baseURL = baseURL;

  axios.interceptors.request.use(
    config => {
      config.headers.common['client_date'] = moment().format();
      config.headers.common['client_timezone'] = jstz.determine().name();

      if (!config.headers['authorization']) {
        config.headers['authorization'] = JWT.fetch();
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    response => {
      console.log(response.status, response.statusText, response.config.url, response.data);
      return response;
    },
    error => {
      console.error(error.message);
      if (error.response) {
        console.log(error.response.status, error.response.config.url, error.response.data);
        if (parseInt(error.response.status) !== 401) {
          // got Unauthorized response
        } else {
          // bugsnagClient.notify(error);
        }
      } else {
        // bugsnagClient.notify(error);
        // TODO : the connection probably failed?
      }
      return Promise.reject(error);
    }
  );

  return axios;
}
