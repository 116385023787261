import leadConfig from './lead.config';
import signinConfig from './signin.config';
import checkoutConfig from './checkout.config';
import infoConfig from './info.config';
import createEventConfig from './createEvent.config';

export const defaultFormValues = (form_id) => {
  switch (form_id) {
    case 'leadForm':
      return leadConfig;

    case 'signinForm':
      return signinConfig;

    case 'checkoutForm':
      return checkoutConfig;

    case 'infoForm':
      return infoConfig;

    case 'createEventForm':
      return createEventConfig;

    default:
      return {};
  }
};
