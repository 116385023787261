import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connect from 'utils/route_connect';
import appVersion from 'utils/checkAppVersion';
import isConnected from 'utils/isConnected';
import LOADING_KEY from 'definitions/keys/loading.keys';
import {
  verifyAuth,
  signOut,
} from 'state/actions';

import DataLoading from 'components/DataLoading/DataLoading';

export default function(ResourcePage) {
  class AuthHawk extends Component {
        verifyInterval = null;

        componentDidMount() {
          clearInterval(this.verifyInterval);
          this.handleVerification(this.props);
        }

        componentWillReceiveProps(nextProps) {
          clearInterval(this.verifyInterval);
          this.handleVerification(nextProps, true);
        }

        componentWillUnmount() {
          clearInterval(this.verifyInterval);
        }

        setVerifyInterval = () => {
          clearInterval(this.verifyInterval);

          this.verifyInterval = setInterval(() => {
            const { verifyAuth } = this.props;

            /* run this check all the time, as long as the event has not ended */

            isConnected()
              .then(() => {
                verifyAuth(false)
                  .then(() => {
                    console.log('[AuthHawk] Auto Verify Auth Success');
                  })
                  .catch((e) => {
                    console.log('[AuthHawk] Auto Verify Auth Error', e);
                  });
              })
              .catch(e => {
                console.log('[AuthHawk] isConnected Error', e);
              });
          }, 10000);
        };

        handleVerification = (props, shouldSignOut = false) => {
          const {

            /* data */
            event,
            loading,
            authenticated,
            location: {
              pathname,
            },

            /* actions */
            signOut,
            verifyAuth,

          } = props;

          if (loading) return;

          if (!authenticated) {
            this.redirect(pathname);
            shouldSignOut && signOut();
            return;
          }

          if (!Object.keys(event).length) {
            verifyAuth()
              .then(() => {
                appVersion.check();
                if (!event.ended) {
                  this.setVerifyInterval();
                }
              })
              .catch(() => {
                this.redirect(pathname);
              });
            return;
          }

          appVersion.check();
          if (!event.ended) {
            this.setVerifyInterval();
          }
        };

        redirect = (fromPath, toPath = '/') => {
          this.props.history.replace({
            pathname: toPath,
            state: {
              from: { pathname: fromPath },
            },
          });
        };

        render() {
          const {
            loading,
            authenticated,
          } = this.props;

          if (loading || !authenticated) {
            return <DataLoading message="Authenticating..." />;
          }
          return <ResourcePage />;
        }
  }

  AuthHawk.propTypes = {
    /* data */
    authenticated: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    event: PropTypes.object.isRequired,

    /* actions */
    verifyAuth: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
  };

  return connect(
    state => ({
      authenticated: state.authenticated,
      event: state.event,
      loading: state.loading[LOADING_KEY.auth],
    }),
    {
      verifyAuth,
      signOut,
    }
  )(AuthHawk);
}
